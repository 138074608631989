import { sendPrompt } from 'api/prompt';
import { useState } from 'react';

export type useGenerateButtonProps = {
    prompt: string | undefined;
    website: string;
};

export const useGenerateButton = ({ prompt, website }: useGenerateButtonProps) => {
    const [draftUrl, setDraftUrl] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const generate = () => {
        if (!prompt || !website) return;
        const filledPrompt = `Bonjour, je souhaite un texte généré en français ABSOLUMENT s'il te plaît !\nJ'ai un site : ${website}\n${prompt}`;
        setDraftUrl(undefined);
        setLoading(true);
        sendPrompt({ prompt: filledPrompt, url: website }).then((data) => {
            setDraftUrl(data.data.draftUrl);
            setLoading(false);
        });
    };
    return {
        loading,
        generate,
        draftUrl
    };
};
