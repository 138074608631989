import React from 'react';
import { useWebsites } from './useWebsites';

export type WebsiteSelectProps = {
    website: string;
    setWebsite: React.Dispatch<React.SetStateAction<string>>;
};

export const WebsiteSelect: React.FC<WebsiteSelectProps> = ({ website, setWebsite }) => {
    const { urls } = useWebsites();
    console.log('urls', urls);
    if (!urls) return <></>;

    return (
        <select value={website} onChange={(e) => e.target.value && setWebsite(e.target.value)}>
            {urls.map((url: string) => (
                <option key={url} value={url}>
                    {url}
                </option>
            ))}
        </select>
    );
};
