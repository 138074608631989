import './GenerateButton.scss';
import React from 'react';
import { useGenerateButton } from './useGenerateButton';

export type GenerateButtonProps = {
    prompt: string | undefined;
    website: string;
};

export const GenerateButton: React.FC<GenerateButtonProps> = ({ prompt, website }) => {
    const { loading, generate, draftUrl } = useGenerateButton({ prompt, website });
    return (
        <div className="buttons-wrapper">
            <button disabled={loading} className="generate" onClick={generate}>
                {loading ? 'En cours de génération...' : "Générer l'article"}
            </button>

            {draftUrl && (
                <a className="draft-post" href={draftUrl} target="_blank">
                    Voir l'article en draft
                </a>
            )}
        </div>
    );
};
