import axiosClient from './client';

export type QuestionPayload = {
    prompt: string;
    url: string;
};

export const getUrls = () => {
    return axiosClient.get('/websites').then((response) => response);
};

export const sendPrompt = ({ prompt, url }: QuestionPayload) => {
    return axiosClient.post('/request/generate', { prompt, url }).then((response) => response);
};
