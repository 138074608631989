import { WebsiteSelect } from 'components/WebsiteSelect/WebsiteSelect';
import './App.scss';
import { Prompt } from './components/Prompt/Prompt';
import { useState } from 'react';
import { GenerateButton } from 'components/GenerateButton/GenerateButton';
import { defaultPrompt } from 'components/Prompt/defaultPrompt';

function App() {
    const [prompt, setPrompt] = useState<string>(defaultPrompt);
    const [website, setWebsite] = useState<string>('https://seo-toulon.getup.agency');

    return (
        <div className="App">
            <WebsiteSelect website={website} setWebsite={setWebsite} />
            <Prompt prompt={prompt} setPrompt={setPrompt} />
            <GenerateButton prompt={prompt} website={website} />
        </div>
    );
}

export default App;
