import { getUrls } from 'api/prompt';
import { useEffect, useState } from 'react';

export const useWebsites = () => {
    const [urls, setUrls] = useState<string[]>();

    useEffect(() => {
        getUrls().then((data) => setUrls(data.data));
    }, []);

    return {
        urls
    };
};
