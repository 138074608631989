import './Prompt.scss';
import React from 'react';

export type PromptProps = {
    prompt: string;
    setPrompt: React.Dispatch<React.SetStateAction<string>>;
};

export const Prompt: React.FC<PromptProps> = ({ prompt, setPrompt }) => {
    return (
        <div className="prompt">
            <h2 className="">Prompt</h2>
            <textarea rows={35} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
        </div>
    );
};
