export const defaultPrompt = `Nous sommes une agence web à 360° et ce site nous permet de mettre en avant nos compétences en SEO et SEA pour nos clients. 
Je souhaiterai que tu me génère le titre d'un article innovant pour nous mettre en avant en le mettant dans cette balise '[TITRE][/TITRE]' s'il te plaît. 
Je souhaiterai que tu me génère une introduction pour cet article dans cette balise '[INTRODUCTION][/INTRODUCTION]'. 
Il faut que ce paragraphe soit du HTML (sans balise <body> ou <head> ou <html>) et qu'il contienne des mots clés en gras, des liens hypertexte vers des sources fiables et une touche d'humour s'il te plaît. 
Je souhaiterai que tu me génère le titre de la première section de cet article qui sera portée sur avant notre expertise dans ce domaine en le mettant dans cette balise '[TITRE_PREMIERE_SECTION][/TITRE_PREMIERE_SECTION]' s'il te plaît. 
Je souhaiterai aussi que tu me génères un paragraphe de 10 lignes en rapport avec ce titre mettant en avant notre expertise dans ce domaine en le mettant dans cette balise '[PARAGRAPHE_PREMIERE_SECTION][/PARAGRAPHE_PREMIERE_SECTION]'. 
Il faut que ce paragraphe soit du HTML (sans balise <body> ou <head> ou <html>) et qu'il contienne des mots clés en gras, des liens hypertexte vers des sources fiables et une touche d'humour s'il te plaît. 
Je souhaiterai que tu me génère le titre de la seconde section de cet article qui sera portée sur le fait que nous sommes proches de nos clients, intéressés par leurs problématiques et aussi très proche d'eux (nous sommes basées vers Toulon) en le mettant dans cette balise '[TITRE_SECONDE_SECTION][/TITRE_SECONDE_SECTION]'. 
Je souhaiterai que tu me génère un paragraphe de 10 lignes en rapport avec ce titre mettant en avant le fait que nous sommes proches de nos clients, intéressés par leurs problématiques et aussi très proche d'eux (nous sommes basées vers Toulon) en le mettant dans cette balise '[PARAGRAPHE_SECONDE_SECTION][/PARAGRAPHE_SECONDE_SECTION]'. 
Je souhaiterai que tu me génère le titre de la dernière section de cet article qui sera portée sur le fait que nous sommes des acteurs jeunes et que nous nous appuyons sur des analyses poussées et aussi des outils dernier cri en le mettant dans cette balise '[TITRE_TROISIEME_SECTION][/TITRE_TROISIEME_SECTION]'. 
Et enfin, je souhaiterai que tu me génère un dernier paragraphe de 6 lignes en rapport avec ce titre mettant en avant le fait que nous sommes des acteurs jeunes et que nous nous appuyons sur des analyses poussées et aussi des outils dernier cri, en le mettant dans cette balise '[PARAGRAPHE_TROISIEME_SECTION][/PARAGRAPHE_TROISIEME_SECTION]' 
Il faut que ce paragraphe soit du HTML (sans balise <body> ou <head> ou <html>) et qu'il contienne des mots clés en gras, des liens hypertexte vers des sources fiables et une touche d'humour. 
Il faut ABSOLUMENT fermer les balises correctement /!\ 
En AUCUN cas tu ne dois oublier de me remplire une des balises /!\ 
Merci beaucoup !;`;
